import { FunctionComponentWithChildren } from '@bonsai-components/utility-types';
import { Box, keyframes, useTheme } from '@mui/material';
import { FunctionComponent, useLayoutEffect, useState } from 'react';
import { useSystemTheme } from '../../hooks/use-system-theme.hook';
import { moderneColors } from '../../themes/moderne-theme';
import { CenteredBox } from '../styled-components/layouts/layouts.styled';

export const UnauthenticatedLayout: FunctionComponentWithChildren = ({
  children
}) => {
  const [domRect, setDomRect] = useState<DOMRect>();
  const { isSystemThemeDark } = useSystemTheme();
  const theme = useTheme();

  useLayoutEffect(() => {
    const handleResize = () => {
      setDomRect(document.body.getBoundingClientRect());
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const radius = Math.sqrt(
    Math.pow((domRect?.width ?? 0) / 2, 2) +
      Math.pow((domRect?.height ?? 0) / 2, 2)
  );

  return (
    <Box>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,

          backgroundColor: isSystemThemeDark
            ? moderneColors.indigo[400]
            : undefined
        }}>
        <BackgroundOrb
          anchor="top-left"
          color={
            isSystemThemeDark
              ? moderneColors.blue.iceBlue
              : theme.palette.blue['main']
          }
          radius={radius}
        />
        <BackgroundOrb
          anchor="top-right"
          color={
            isSystemThemeDark
              ? moderneColors.roseate.magenta
              : theme.palette.red['main']
          }
          radius={radius}
        />
        <BackgroundOrb
          anchor="bottom-left"
          color={moderneColors.roseate.recipeRunPurple}
          radius={radius}
        />
        <BackgroundOrb
          anchor="bottom-right"
          color={
            isSystemThemeDark
              ? moderneColors.yellow[400]
              : theme.palette.green['main']
          }
          radius={radius}
        />
      </Box>
      <Box
        sx={{
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent: 'center',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          p: { xs: 1, md: 0 },
          backdropFilter: 'blur(100px)',

          backgroundColor: (theme) =>
            isSystemThemeDark
              ? `${theme.palette.indigo[800]}80`
              : `${theme.palette.indigo[150]}66`
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            position: 'relative',
            height: '100%'
          }}>
          <Box
            sx={{
              position: 'relative'
            }}>
            <Box
              sx={{
                zIndex: 3
              }}>
              <CenteredBox
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                  paddingLeft: { xs: 3, sm: 4 },
                  paddingRight: { xs: 3, sm: 4 },
                  paddingTop: { xs: 3, sm: 4 },
                  paddingBottom: { xs: 3, sm: 4 },
                  boxShadow: 3,
                  borderRadius: 1,
                  backgroundColor: 'rgba(18, 18, 20, 0.84)'
                }}>
                <Box
                  sx={{
                    paddingLeft: { xs: 1, sm: 8 },
                    paddingRight: { xs: 1, sm: 8 },
                    paddingTop: { xs: 4, sm: 3 },
                    paddingBottom: { xs: 4, sm: 3 },
                    border: (theme) => `2px solid ${theme.palette.grey[400]}`
                  }}>
                  {children}
                </Box>
              </CenteredBox>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

type BackgroundOrbProps = {
  anchor: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
  color: string;
  radius?: number;
};

const BackgroundOrb: FunctionComponent<BackgroundOrbProps> = ({
  anchor,
  color,
  radius = 200
}) => {
  // number between 30 and 60
  const randomMove = Math.floor(Math.random() * 30) + 30;

  // random number between 20 and 30
  const randomSeconds = Math.floor(Math.random() * 10) + 20;

  const halfRadius = radius / 2;

  let x, y, left, top, right, bottom;

  if (anchor === 'top-left') {
    x = -halfRadius;
    y = -halfRadius;
    top = 0;
    left = 0;
  } else if (anchor === 'top-right') {
    x = halfRadius;
    y = -halfRadius;
    top = 0;
    right = 0;
  } else if (anchor === 'bottom-left') {
    x = -halfRadius;
    y = halfRadius;
    bottom = 0;
    left = 0;
  } else if (anchor === 'bottom-right') {
    x = halfRadius;
    y = halfRadius;
    bottom = 0;
    right = 0;
  } else {
    x = 0;
    y = 0;
  }

  const nebula = keyframes`
    0% { transform: scale(1) translate(${x}px, ${y}px); }
    25% { transform: scale(1.2) translate(${x + 2 * randomMove}px, ${
      y - 2 * randomMove
    }px);}
     50% { transform: scale(0.8) translate(0px, 0px);}
       75% { transform: scale(0.8) translate(${x - 2 * randomMove}px, ${
         x + 2 * randomMove
       }px);}
    100% { transform: scale(1) translate(${x}px, ${y}px); }
  `;

  return (
    <Box
      sx={{
        position: 'fixed',
        top: top,
        right: right,
        bottom: bottom,
        left: left,
        height: radius,
        width: radius,
        borderRadius: radius,
        animation: `${nebula} ${randomSeconds}s infinite`,
        background: `radial-gradient(${color} 25%, #ffffff50)`,
        opacity: 0.97,
        transform: `translate(${x}px, ${y}px)`
      }}
    />
  );
};
